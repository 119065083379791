:root {
  --main-grey: #f2f2f2;
  --second-grey: #d2d2d2;
}

input,
select {
  background-color: transparent !important;
}

.skeleton {
  width: 100%;
  animation-name: skeleton;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  border-radius: 4px;
}

@keyframes skeleton {
  0% {
    background-color: var(--main-grey);
  }
  50% {
    background-color: var(--second-grey);
  }
  100% {
    background-color: var(--main-grey);
  }
}
